import axios from 'axios'
import { doLogout } from './auth'

interface RequestConfig {
    useAuth?: boolean
    multipart?: boolean
    useRecaptcha?: boolean
}

const defaultRequestConfig: RequestConfig = {
    useAuth: true,
    multipart: false,
    useRecaptcha: false,
}

export class HttpError extends Error {
    status: number

    constructor(message: string, status: number) {
        super(message)
        this.status = status
    }
}

function getInstance(requestConfig?: RequestConfig) {
    const config = requestConfig
        ? { ...defaultRequestConfig, ...requestConfig }
        : { ...defaultRequestConfig }

    const baseURL =
        process.env.REACT_APP_MODE === 'cloud'
            ? process.env.REACT_APP_API_ENDPOINT
            : `http://${window.location.hostname}:4000/api`

    const token = localStorage.getItem('token')

    if (config?.useAuth && !token) {
        throw new Error('User not authenticated!')
    }

    const headers = {
        'Content-Type': !config.multipart
            ? 'application/json'
            : 'requestConfig/form-data',
        Authorization: config?.useAuth ? `${token}` : undefined,
        hash_caixa: localStorage.getItem('cashierHash') ?? undefined,
    }

    const instance = axios.create({
        baseURL,
        headers,
    })

    instance.interceptors.request.use(
        async (requestConfig) => {
            if (config.useRecaptcha && process.env.REACT_APP_MODE === 'cloud') {
                const recaptchaToken = await execupteRecaptcha(
                    `${requestConfig.method}_${requestConfig.url}`
                )

                requestConfig.headers['recaptcha-token'] = recaptchaToken
            }

            return requestConfig
        },
        (err) => Promise.reject(err)
    )

    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (
                error.response?.data?.data?.message ===
                'Unauthorized, token expired'
            ) {
                doLogout()
                window.location.href = '/entrar?expired=true'
                return
            }

            throw new HttpError(
                error.response?.data?.data?.message,
                error.response?.status
            )
        }
    )

    return instance
}

export async function get(endpoint: string, requestConfig?: RequestConfig) {
    const axios = getInstance(requestConfig)
    const res = await axios.get(endpoint)
    return res
}

export async function post(
    endpoint: string,
    body: any,
    requestConfig?: RequestConfig
) {
    const axios = getInstance(requestConfig)
    const res = await axios.post(endpoint, body)
    return res
}

export async function put(
    endpoint: string,
    body: any,
    requestConfig?: RequestConfig
) {
    const axios = getInstance(requestConfig)
    const res = await axios.put(endpoint, body)
    return res
}

export async function del(endpoint: string, requestConfig?: RequestConfig) {
    const axios = getInstance(requestConfig)
    const res = await axios.delete(endpoint)
    return res
}

export function execupteRecaptcha(action?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY

        if (
            !window.grecaptcha ||
            !key ||
            process.env.REACT_APP_MODE !== 'cloud'
        ) {
            reject()
            return
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(key, { action: /* action ?? */ 'submit' })
                .then(
                    (token) => resolve(token),
                    (err) => reject(err)
                )
        })
    })
}
