import jwtDecode from 'jwt-decode'
import validator from 'validator'
import { post } from './generic'

interface LoginInterface {
    username: string
    senha: string
    login_image?: string
    company?: string
}

export class FaceImageRequiredError extends Error {
    constructor() {
        super()
    }
}

export const doLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('timeDiff')
}

export function isLogged() {
    const token = localStorage.getItem('token')
    const timeDiff = localStorage.getItem('timeDiff')

    if (token && validator.isJWT(token) && timeDiff) {
        const jwt = jwtDecode<any>(token)
        if (
            jwt &&
            jwt.exp &&
            jwt.exp > Math.floor(new Date().valueOf() / 1000 - Number(timeDiff))
        ) {
            return true
        }
    }

    return false
}

export async function doLogin(data: LoginInterface): Promise<void> {
    try {
        const res = await post('login', data, {
            useAuth: false,
            useRecaptcha: true,
        })
        const token = res.data.data.token
        const { iat } = jwtDecode<any>(token)
        const timeDiff = Math.floor(new Date().valueOf() / 1000 - Number(iat))
        localStorage.setItem('token', token)
        localStorage.setItem('timeDiff', timeDiff.toString())
    } catch (error: any) {
        if (error.message.includes('Face image required'))
            throw new FaceImageRequiredError()
        throw error
    }
}

export async function doRefreshToken(): Promise<void> {
    const res = await post(
        'refresh-token',
        {},
        {
            useAuth: true,
            useRecaptcha: true,
        }
    )
    const token = res.data.data.token
    const { iat } = jwtDecode<any>(token)
    const timeDiff = Math.floor(new Date().valueOf() / 1000 - Number(iat))
    localStorage.setItem('token', token)
    localStorage.setItem('timeDiff', timeDiff.toString())
}
