import {
    BuyInfo,
    BuyReportsInfo,
    BuyedProductsInfo,
    ConsignationInfo,
    PaymentInBuy,
} from '../context/buy_context'
import { CoinInfo } from '../context/coin_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { get, post, put } from './generic'
import { ParseProduct, ProductApiData } from './product'
import {
    CashierClosedSaleError,
    CreditNoteApiData,
    parseCreditNote,
} from './sale'
import { SupplierApiData, parseSupplier } from './supplier'

export class CashierClosedBuyError extends Error {
    constructor() {
        super()
    }
}

export interface CreatePurchasePaymentInterface {
    id_compra?: number | null
    id_moeda: number
    id_moeda_troco: number
    valor_pagamento: number
    id_metodo_pagamento: number
    flag_descontar_caixa: number
    comprovante_texto?: string
    comprovante_imagem?: string
}

export interface CreateBuyInterface {
    numero_fatura: string
    flag_forma_pagamento: number
    id_fornecedor: number
    produtos: {
        quantidade_produto: number
        id_produto: number
        preco_compra: number
        preco_venda: number
        porc_lucro: number
        exchange?: {
            id_moeda_estrangeira: number
            valor_moeda_estrangeira: number
            cambio: number
            flag_cambio_reverso: number
        }
    }[]
    purchase_payment?: CreatePurchasePaymentInterface | null
    id_filial_master?: number | null
    despacho: number
    frete: number
    descarga: number
    total_manual: number
    data_consignacao?: string | null
}

export interface UpdateBuyInterface {
    id_compra: number
    flag_forma_pagamento?: number
    id_fornecedor?: number
    data?: string
    hora?: string
    data_consignacao?: string | null
    produtos?: {
        quantidade_produto: number
        id_produto: number
        preco_compra: number
        preco_venda: number
        porc_lucro: number
        exchange?: {
            id_moeda_estrangeira: number
            valor_moeda_estrangeira: number
            cambio: number
            flag_cambio_reverso: number
        }
    }[]
}

export interface FinalizeConsignationInterface {
    id_compra: number
    products: {
        id_produto_compra: number
        quantidade: number
    }[]
}

export interface ListBuysInterface {
    skip: number
    take: number
    coins: CoinInfo[]
    search?: string
    id_fornecedor?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_filial?: number[]
    status?: number[]
    data_inicial?: string
    data_final?: string
}

export interface ListBuyReportsInterface {
    coins: CoinInfo[]
    search?: string
    id_fornecedor?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    status?: number
    data_inicial?: string
    data_final?: string
}

export interface BuyApiData {
    id_compra: number
    data: string
    flag_forma_pagamento: number
    fornecedor: SupplierApiData
    hora: string
    id_moeda: number
    compra_total: string
    valor_pago: string
    iva_total: string
    numero_caixa: number
    numero_fatura: string
    produtos_compra: {
        cambio: string
        id_compra: number
        id_moeda_estrangeira: number
        id_produto: number
        id_produto_compra: number
        preco_compra: string
        preco_venda: string
        preco_compra_anterior: string
        preco_venda_anterior: string
        produto: ProductApiData
        quantidade_produto: string
        quantidade_devolvida: string
        valor_moeda_estrangeira: string
        flag_cambio_reverso: number | null
        porc_lucro: number
        porc_lucro_anterior: number
    }[]
    flag_horario_verao: number
    status_compra: number
    filial: BranchApiData | null
    usuario: {
        nome: string
    } | null
    caixa: {
        nome: string
    } | null
    despacho: string
    frete: string
    descarga: string
    total_manual: string
    data_consignacao?: string | null
    status_consignacao: number
    notasCredito?: {
        notaCredito: CreditNoteApiData
    }[]
}

export interface BuyedProductsApiData {
    product: string
    averagePurchasePrice: number
    qntPurchased: number
    qntPurchases: number
    coinId: number
    productId: number
}

export interface ListBuyedProductsInterface {
    data_inicial?: string
    data_final?: string
    order_by?: number
    skip?: number
    take?: number
    productIds?: number[]
    id_fornecedor?: number[]
    id_marca?: number[]
}

export interface BuyReportsApiData {
    coinId: number
    totalPurchased: number
    totalPaid: number
    totalPurchases: number
}

export interface GenerateBuyReportsInterface {
    search?: string
    id_fornecedor?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_filial?: number[]
    status?: number
    data_inicial?: string
    data_final?: string
    selectedFilters: Record<string, string>
}

export interface PurchasePaymentApiData {
    data_lancamento: string
    hora_lancamento: string
    id_fornecedor: number
    id_moeda: number
    id_moeda_troco: number
    id_usuario: number
    id_compra: number
    id_compra_pagamento: number
    valor_pagamento: string
    valor_moeda_secundaria: string
    troco_moeda_secundaria: string
    flag_cambio_reverso: number | null
    cambio: string
    caixa: {
        nome: string
    } | null
    usuario: {
        nome: string
    } | null
    id_metodo_pagamento: number
    metodoPagamento: {
        nome: string
        flag_ativo: number
    }
    comprovante_texto: string | null
    comprovante_imagem: string | null
    troco: string
    flag_horario_verao: number
    status_pagamento: number
    flag_descontar_caixa: number
}

export interface ConsignationApiData {
    id_consignacao: number
    data: string
    hora: string
    id_usuario: number
    id_compra: number
    produtosConsignacao: {
        id_produto_consignacao: number
        produtoCompra: {
            cambio: string
            id_compra: number
            id_moeda_estrangeira: number
            id_produto: number
            id_produto_compra: number
            preco_compra: string
            preco_venda: string
            preco_compra_anterior: string
            preco_venda_anterior: string
            produto: ProductApiData
            quantidade_produto: string
            quantidade_devolvida: string
            valor_moeda_estrangeira: string
            flag_cambio_reverso: number | null
            porc_lucro: number
            porc_lucro_anterior: number
        }
        quantidade_produto: string
    }[]
    valor_total: string
    usuario: {
        nome: string
    }
    flag_horario_verao: number
    caixa: {
        nome: string
    } | null
}

export function parseBuy(data: BuyApiData, coins: CoinInfo[]): BuyInfo {
    return {
        id: data.id_compra,
        bill: data.numero_fatura,
        paymentType: data.flag_forma_pagamento,
        supplier: data.fornecedor ? parseSupplier(data.fornecedor) : null,
        total: Number(data.compra_total),
        totalAmountPayed: Number(data.valor_pago),
        totalIva: Number(data.iva_total),
        date: data.data,
        hour: data.hora,
        products: data.produtos_compra?.map((produto_compra) => ({
            id: produto_compra.id_produto_compra,
            product: ParseProduct(produto_compra.produto),
            price: Number(produto_compra.preco_compra),
            salePrice: Number(produto_compra.preco_venda),
            porcentGain: Number(produto_compra.porc_lucro),
            qnt: Number(produto_compra.quantidade_produto),
            returnedQnt: Number(produto_compra.quantidade_devolvida),
            exchange: produto_compra.cambio
                ? {
                      exchange: Number(produto_compra.cambio),
                      amount: Number(produto_compra.valor_moeda_estrangeira),
                      coin: coins.filter(
                          (c) => c.id === produto_compra.id_moeda_estrangeira
                      )[0],
                      flagReverseExchange:
                          produto_compra.flag_cambio_reverso ?? 0,
                  }
                : null,
            subtotal:
                Number(produto_compra.preco_compra) *
                Number(produto_compra.quantidade_produto),
        })),
        coin: coins.filter((c) => c.id === data.id_moeda)[0],
        cashier: {
            id: 0,
            hash: '',
            name: data.caixa?.nome ?? '',
            isActive: false,
            branch: null,
        },
        account: {
            id: 0,
            name: data.usuario?.nome ?? '',
            username: '',
            password: '',
            cpassword: '',
            rules: [],
            isMaster: false,
            isActive: false,
            loginWithImage: false,
            image: null,
            branch: null,
        },
        payments: [],
        isSummerTime: Boolean(data.flag_horario_verao),
        paymentStatus: data.status_compra,
        branch: data.filial ? parseBranch(data.filial) : null,
        freight: Number(data.frete),
        dispatch: Number(data.despacho),
        discharge: Number(data.descarga),
        manualTotal: Number(data.total_manual),
        consignationDate: data.data_consignacao
            ? new Date(`${data.data_consignacao} 00:00:00`)
            : null,
        consignationStatus: data.status_consignacao,
        creditNotes: data.notasCredito
            ? data.notasCredito.map((nc) =>
                  parseCreditNote(nc.notaCredito, coins)
              )
            : undefined,
    }
}

function parseBuyReports(
    data: BuyReportsApiData,
    coins: CoinInfo[]
): BuyReportsInfo {
    return {
        coinId: data.coinId,
        totalPaid: data.totalPaid,
        totalBuyed: data.totalPurchased,
        totalBuys: data.totalPurchases,
        coin: coins.find((c) => c.id === data.coinId),
    }
}

function parseBuyedProducts(
    data: BuyedProductsApiData,
    coins: CoinInfo[]
): BuyedProductsInfo {
    return {
        product: data.product,
        averageBuyPrice: Number(data.averagePurchasePrice),
        qntBuyed: Number(data.qntPurchased),
        qntBuys: Number(data.qntPurchases),
        productId: data.productId,
        coin: coins.find((c) => c.id === data.coinId),
    }
}

function parsePurchasePayment(
    data: PurchasePaymentApiData,
    coins?: CoinInfo[],
    buy?: BuyInfo
): PaymentInBuy {
    return {
        id: data.id_compra_pagamento,
        amountPayed: Number(data.valor_pagamento),
        paymentCoin: coins?.find((c) => c.id === data.id_moeda) ?? null,
        changeCoin: coins?.find((c) => c.id === data.id_moeda_troco) ?? null,
        paymentMethod: {
            id: data.id_metodo_pagamento,
            name: data.metodoPagamento.nome,
            isActive: Boolean(data.metodoPagamento.flag_ativo),
        },
        exchange:
            data.id_moeda !== buy?.coin?.id
                ? {
                      exchangeValue: Number(data.cambio),
                      exchangeAmountPayed: Number(data.valor_moeda_secundaria),
                      flagReverseExchange: data.flag_cambio_reverso ?? 0,
                  }
                : undefined,
        date: data.data_lancamento,
        hour: data.hora_lancamento,
        cashier: data.caixa
            ? {
                  id: 0,
                  hash: '',
                  name: data.caixa.nome,
                  isActive: false,
                  branch: null,
              }
            : undefined,
        madeBy: data.usuario
            ? {
                  id: 0,
                  name: data.usuario.nome,
                  username: '',
                  password: '',
                  cpassword: '',
                  rules: [],
                  isMaster: false,
                  isActive: false,
                  loginWithImage: false,
                  image: null,
                  branch: null,
              }
            : null,
        proofText: data.comprovante_texto ?? undefined,
        proofImage: data.comprovante_imagem ?? undefined,
        change: Number(data.troco),
        isSummerTime: Boolean(data.flag_horario_verao),
        paymentStatus: data.status_pagamento,
        exchangeChange: data.troco_moeda_secundaria
            ? Number(data.troco_moeda_secundaria)
            : null,
        cashierDiscounted: Boolean(data.flag_descontar_caixa),
    }
}

function parseConsignation(
    data: ConsignationApiData,
    coins: CoinInfo[]
): ConsignationInfo {
    return {
        total: Number(data.valor_total),
        date: data.data,
        hour: data.hora,
        id: data.id_consignacao,
        products: data.produtosConsignacao.map((p) => ({
            id: p.id_produto_consignacao,
            productInBuy: {
                id: p.produtoCompra.id_produto_compra,
                product: ParseProduct(p.produtoCompra.produto),
                price: Number(p.produtoCompra.preco_compra),
                salePrice: Number(p.produtoCompra.preco_venda),
                porcentGain: Number(p.produtoCompra.porc_lucro),
                qnt: Number(p.produtoCompra.quantidade_produto),
                returnedQnt: Number(p.produtoCompra.quantidade_devolvida),
                exchange: p.produtoCompra.cambio
                    ? {
                          exchange: Number(p.produtoCompra.cambio),
                          amount: Number(
                              p.produtoCompra.valor_moeda_estrangeira
                          ),
                          coin: coins.filter(
                              (c) =>
                                  c.id === p.produtoCompra.id_moeda_estrangeira
                          )[0],
                          flagReverseExchange:
                              p.produtoCompra.flag_cambio_reverso ?? 0,
                      }
                    : null,
                subtotal:
                    Number(p.produtoCompra.preco_compra) *
                    Number(p.produtoCompra.quantidade_produto),
            },
            qnt: Number(p.quantidade_produto),
        })),
        cashier: data.caixa
            ? {
                  name: data.caixa.nome,
              }
            : undefined,
        account: data.usuario
            ? {
                  name: data.usuario.nome,
              }
            : undefined,
        isSummerTime: Boolean(data.flag_horario_verao),
    }
}

export async function createBuy(data: CreateBuyInterface): Promise<void> {
    try {
        await post('purchase', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedBuyError()
        throw error
    }
}

export async function updateBuy(data: UpdateBuyInterface): Promise<void> {
    await put('purchase', data, {
        useRecaptcha: true,
    })
}

export async function listBuys(
    data: ListBuysInterface
): Promise<ListReturn<BuyInfo>> {
    const res = await post('purchases', {
        skip: data.skip,
        take: data.take,
        search: data.search,
        id_fornecedor: data.id_fornecedor,
        id_usuario: data.id_usuario,
        id_caixa: data.id_caixa,
        id_filial: data.id_filial,
        status: data.status,
        data_inicial: data.data_inicial,
        data_final: data.data_final,
    })
    return {
        data: res.data.data.purchases.data.map((b: BuyApiData) =>
            parseBuy(b, data.coins)
        ),
        count: res.data.data.purchases.count,
    }
}

export async function listBuyReports(
    data: ListBuyReportsInterface
): Promise<BuyReportsInfo[]> {
    const res = await post('reports/purchase', {
        search: data.search,
        id_fornecedor: data.id_fornecedor,
        id_usuario: data.id_usuario,
        id_caixa: data.id_caixa,
        status: data.status,
        data_inicial: data.data_inicial,
        data_final: data.data_final,
    })
    return res.data.data.map((b: BuyReportsApiData) =>
        parseBuyReports(b, data.coins)
    )
}

export async function generateBuyReports(
    data: GenerateBuyReportsInterface
): Promise<void> {
    await post(
        'reports/purchase/generate',
        {
            search: data.search,
            id_fornecedor: data.id_fornecedor,
            id_usuario: data.id_usuario,
            id_filial: data.id_filial,
            id_caixa: data.id_caixa,
            status: data.status,
            data_inicial: data.data_inicial,
            data_final: data.data_final,
            selectedFilters: data.selectedFilters,
        },
        {
            useRecaptcha: true,
        }
    )
}

export async function getBuyById(
    id: number,
    coins: CoinInfo[]
): Promise<BuyInfo> {
    const res = await get(`purchase/${id}`)
    return parseBuy(res.data.data, coins)
}

export async function listBuyedProducts(
    data: ListBuyedProductsInterface,
    coins: CoinInfo[]
): Promise<BuyedProductsInfo[]> {
    const res = await post('reports/purchased-products', data)
    return res.data.data.map((data: BuyedProductsApiData) =>
        parseBuyedProducts(data, coins)
    )
}

export async function closeOpenedBuys(ids: number[]): Promise<void> {
    await post(
        'purchase/close',
        { id_compra: ids },
        {
            useRecaptcha: true,
        }
    )
}

export async function cancelBuy(id: number): Promise<void> {
    await post(
        'purchase/cancel',
        { id_compra: id },
        {
            useRecaptcha: true,
        }
    )
}

export async function createBuyPayment(data: CreatePurchasePaymentInterface) {
    try {
        await post('purchase-payment', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function listBuyPayments(
    buyId: number,
    coins?: CoinInfo[],
    buy?: BuyInfo
): Promise<PaymentInBuy[]> {
    const res = await post('purchase-payments', {
        id_compra: [buyId],
    })
    return res.data.data.map((p: PurchasePaymentApiData) =>
        parsePurchasePayment(p, coins, buy)
    )
}

export async function finalizeConsignation(
    data: FinalizeConsignationInterface
): Promise<void> {
    await post('purchase/finalize-consignation', data, {
        useRecaptcha: true,
    })
}

export async function findConsignation(buyId: number, coins: CoinInfo[]) {
    const res = await get(`purchase/consignation/${buyId}`)
    return parseConsignation(res.data.data, coins)
}
