import { CoinInfo } from '../context/coin_context'
import { SpentInfo, SpentTypeInfo } from '../context/spent_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { CashierClosedBuyError } from './buy'
import { get, post } from './generic'
import { PaymentMethodApiData, parsePaymentMethod } from './payment'

interface SpentTypeApiData {
    id_tipo_gasto: number
    nome: string
}

function parseSpentType(data: SpentTypeApiData): SpentTypeInfo {
    return {
        id: data.id_tipo_gasto,
        name: data.nome,
    }
}

interface CreateSpentInterface {
    id_tipo_gasto: number
    id_moeda: number
    valor: number
    id_metodo_pagamento: number
    descricao?: string
    id_filial_master?: number | null
}

interface ListSpentsInterface {
    skip: number
    take: number
    id_tipo_gasto?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    data_inicial?: string
    data_final?: string
}

interface SpentApiData {
    id_gasto: number
    id_tipo_gasto: number
    valor: string
    descricao: string | null
    id_caixa: number
    id_usuario: number
    id_moeda: number
    tipoGasto: { nome: string }
    usuario: { nome: string }
    caixa: { nome: string }
    data: string
    hora: string
    flag_horario_verao: number
    metodoPagamento: PaymentMethodApiData
    filial: BranchApiData | null
}

function parseSpent(data: SpentApiData, coins: CoinInfo[]): SpentInfo {
    return {
        id: data.id_gasto,
        account: { id: data.id_usuario, name: data.usuario.nome },
        cashier: { id: data.id_caixa, name: data.caixa?.nome },
        coin: coins.find((c) => c.id === data.id_moeda) ?? null,
        spentType: { id: data.id_tipo_gasto, name: data.tipoGasto.nome },
        value: Number(data.valor),
        date: data.data,
        hour: data.hora,
        description: data.descricao ?? undefined,
        isSummerTime: Boolean(data.flag_horario_verao),
        paymentMethod: parsePaymentMethod(data.metodoPagamento),
        branch: data.filial ? parseBranch(data.filial) : null,
    }
}

export async function listSpentTypes(): Promise<SpentTypeInfo[]> {
    const res = await get('spent-type')

    return res.data.data.map(parseSpentType)
}

export async function createSpentType(name: string): Promise<number> {
    const res = await post(
        'spent-type',
        {
            nome: name,
        },
        {
            useRecaptcha: true,
        }
    )

    return res.data.data.id_tipo_gasto
}

export async function createSpent(data: CreateSpentInterface): Promise<void> {
    try {
        await post('spent', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedBuyError()
        throw error
    }
}

export async function listSpents(
    data: ListSpentsInterface,
    coins: CoinInfo[]
): Promise<ListReturn<SpentInfo>> {
    const res = await post('spents', data)

    return {
        data: res.data.data.data.map((data: SpentApiData) =>
            parseSpent(data, coins)
        ),
        count: res.data.data.count,
    }
}
