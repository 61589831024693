import { CoinInfo } from '../context/coin_context'
import {
    BarcodeTypeEnum,
    IvaEnum,
    LoadStockInfo,
    ProductHistoricInfo,
    ProductInfo,
} from '../context/product_context'
import { TransferStockInfo } from '../pages/Products/TransferStock'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { ClientApiData, parseClient } from './client'
import { EmployeeApiData, parseEmployee } from './employee'
import { get, post, put } from './generic'
import { NoteApiData, parseNote } from './note'
import { parseSupplier, SupplierApiData } from './supplier'
import { parseVehicle, VehicleApiData } from './vehicle'

export class BarcodeAlreadyInUseError extends Error {
    constructor(public readonly barcodes: string[]) {
        super()
    }
}

export interface CreateProductInterface {
    nome: string
    stock_minimo: number | null
    stock_ideal: number | null
    stock_atual?: number | null
    aplicacao: string | null
    observacao: string | null
    custo_cif: number | null
    id_grupo: number | null
    id_sub_grupo: number | null
    id_marca: number | null
    id_modelo: number | null
    path_imagem: string | null
    valor_iva: number
    flag_controlar_estoque: number
    flag_sem_estoque: number
    flag_imprimir_fatura: number
    flag_ativo: number
    flag_autosoma: number
    flag_autopreco: number
    codigo_barras: string[]
    codigo_interno: string[]
    unit_measurement: string
    preço: {
        valor: number
        quantidade: number
    }[]
    preço_nome: {
        valor: number
        porc_lucro: number
        nome: string
        clientes: number[]
    }[]
    valor_compra: number
    porc_lucro: number
    id_fornecedor: number | null
    localizations: {
        valor: string
        id_filial: number | null
    }[]
    productsStockAfterOperation: {
        id_produto_carga: number
        quantidade_produto: number
    }[]
}

export interface UpdateProductInterface
    extends Partial<CreateProductInterface> {
    id_produto: number
    flag_deletado?: number
}

export interface ListProductInterface {
    skip: number
    take: number
    search?: string | null
    groups?: number[] | null
    subgroups?: number[] | null
    brands?: number[] | null
    models?: number[] | null
    stockLevel?: number | null
    id_filial_filter?: number
    dontCount?: boolean
    orderBy?: 'name' | 'localization' | 'price'
}

export interface GenerateInventoryInterface {
    search?: string
    groups?: number[] | null
    subgroups?: number[] | null
    brands?: number[] | null
    models?: number[] | null
    stockLevel?: number | null
    id_filial_filter?: number
    selectedFilters: Record<string, string>
    orderBy?: 'name' | 'localization' | 'price'
}

export interface ListProductHistoricInterface {
    id_produto: number
    skip: number
    take: number
    id_cliente?: number
    tipo?: string
}

export interface ProductApiData {
    nome: string
    stock_minimo: string | null
    stock_ideal: string | null
    stock_atual: string | null
    aplicacao: string | null
    observacao: string | null
    custo_cif: string | null
    id_produto: number
    id_grupo: number | null
    id_sub_grupo: number | null
    id_marca: number | null
    id_modelo: number | null
    path_imagem: string | null
    iva_value: string
    flag_stock_control: number
    flag_no_stock: number
    flag_print: number
    flag_active: number
    flag_autosoma: number
    flag_autopreco: number
    barcode: {
        id_codigo_barra: number
        codigo: string
        id_produto: number
        flag_tipo_codigo: number
    }[]
    unit_measurement: string
    product_price: {
        valor_venda: string
        porc_lucro: string
        quantidade: string | null
        nome: string | null
        id_produto: number
        id_valor_produto: number
        clienteValorProdutos: {
            cliente: ClientApiData
        }[]
    }[]
    valor_compra: string
    porc_lucro: string
    weight?: number
    estoqueFiliais?: {
        id_estoque_filial: number
        id_produto: number
        id_filial: number
        estoque: string
    }[]
    fornecedor: SupplierApiData | null
    localizacoesProduto: {
        valor: string
        id_filial?: number
    }[]
    produtosCargaEstoquePosOperacao: {
        produtoCarga: ProductApiData
        quantidade_produto: string
    }[]
}

export interface ProductHistoricApiData {
    id_operacao: number
    data: string
    hora: string
    valor: string
    quantidade: string
    tipo: 'entrada' | 'saida' | 'carga_estoque' | 'transferencia_estoque'
    flag_horario_verao: number
    nome_agente: string | null
    nome_filial: string | null
    id_moeda: number
    id_moeda_estrangeira: number | null
    cambio: string | null
    valor_moeda_estrangeira: string | null
    flag_cambio_reverso: number | null
}

export interface LoadStockInterface {
    products: {
        id_produto: number
        quantidade_produto: number
        quantidade_produto_negativo: number
    }[]
    id_filial_master?: number | null
}

export interface ListLoadStockInterface {
    skip: number
    take: number
    id_filial?: number[]
    data_inicial?: string
    data_final?: string
}

export interface LoadStockApiData {
    id_carga_estoque: number
    data: string
    hora: string
    produtosCargaEstoque: {
        id_produto_carga_estoque: number
        id_produto: number
        produto: ProductApiData
        quantidade_produto: number
        quantidade_produto_negativo: number
    }[]
    flag_horario_verao: number
    filial: BranchApiData | null
    usuario: {
        nome: string
    } | null
    caixa: {
        nome: string
    } | null
}

export interface CraeteTransferStockInterface {
    products: {
        id_produto: number
        quantidade_produto: number
    }[]
    data_chegada: string
    data_saida: string
    hora_chegada: string
    hora_saida: string
    id_filial_destino: number
    id_filial_origem: number
    id_funcionario: number
    id_veiculo: number
    distancia_estimada: number
}

export interface UpdateTransferStockInterface {
    id_transferencia_estoque: number
    use_legal_note?: boolean
}

export interface ListTransferStock {
    skip: number
    take: number
    search?: string
}

export interface TransferStockApiData {
    id_transferencia_estoque: number
    data: string
    hora: string
    data_chegada: string
    data_saida: string
    hora_chegada: string
    hora_saida: string
    id_filial_destino: number
    id_filial_origem: number
    id_funcionario: number
    id_veiculo: number
    produtosTransferenciaEstoque: {
        id_produto_transferencia_estoque: number
        id_produto: number
        produto: ProductApiData
        quantidade_produto: number
    }[]
    flag_horario_verao: number
    filialOrigem: BranchApiData
    filialDestino: BranchApiData
    funcionario: EmployeeApiData
    veiculo: VehicleApiData
    usuario: {
        nome: string
    } | null
    caixa: {
        nome: string
    } | null
    numero_fatura: string | null
    nota: NoteApiData | null
    distancia_estimada: string
    cdc_fatura?: string
    qrcode?: string
    status_fatura_eletronica?: number
    log_envio_fatura?: string
}

export function ParseProduct(data: ProductApiData): ProductInfo {
    return {
        aplication: data.aplicacao,
        barcodes: data.barcode?.filter(
            (b) => b.flag_tipo_codigo === BarcodeTypeEnum.Barcode
        ).length
            ? data.barcode
                  .filter((b) => b.flag_tipo_codigo === BarcodeTypeEnum.Barcode)
                  .map((barcode) => barcode.codigo)
            : [''],
        internalCodes: data.barcode?.filter(
            (b) => b.flag_tipo_codigo === BarcodeTypeEnum.Internal
        ).length
            ? data.barcode
                  .filter(
                      (b) => b.flag_tipo_codigo === BarcodeTypeEnum.Internal
                  )
                  .map((barcode) => barcode.codigo)
            : [''],
        buyPrice: Number(data.valor_compra),
        stock: Number(data.stock_atual),
        idealStock: data.stock_ideal ? Number(data.stock_ideal) : null,
        minStock: data.stock_minimo ? Number(data.stock_minimo) : null,
        image: data.path_imagem
            ? {
                  content: data.path_imagem,
                  file: new File([], data.path_imagem),
                  isBase64: false,
              }
            : null,
        name: data.nome,
        observation: data.observacao,
        porcentGain: Number(data.porc_lucro),
        iva:
            Number(data.iva_value) === 10
                ? IvaEnum.Dez
                : Number(data.iva_value) === 5
                  ? IvaEnum.Cinco
                  : IvaEnum.Exento,
        showIva: Boolean(data.flag_print),
        sellWithoutStock: Boolean(data.flag_no_stock),
        selectedGroup: data.id_grupo ? { id: data.id_grupo, name: '' } : null,
        selectedSubGroup:
            data.id_sub_grupo && data.id_grupo
                ? { id: data.id_sub_grupo, name: '', groupId: data.id_grupo }
                : null,
        selectedMark: data.id_marca ? { id: data.id_marca, name: '' } : null,
        selectedModel:
            data.id_modelo && data.id_marca
                ? { id: data.id_modelo, name: '', markId: data.id_marca }
                : null,
        unitType: data.unit_measurement,
        salePrice: data.product_price
            ? data.product_price
                  .filter((price) => Number(price.quantidade) === 1)
                  .map((price) => Number(price.valor_venda))
                  .shift() ?? 0
            : 0,
        qntPrice: data.product_price
            ? data.product_price
                  .filter(
                      (price) =>
                          price.quantidade && Number(price.quantidade) > 1
                  )
                  .map((price) => {
                      return {
                          price: Number(price.valor_venda),
                          qnt: Number(price.quantidade),
                      }
                  })
            : [],
        namedPrice: data.product_price
            ? data.product_price
                  .filter((price) => price.nome)
                  .map((price) => {
                      return {
                          price: Number(price.valor_venda),
                          name: price.nome as string,
                          porcentGain: Number(price.porc_lucro),
                          clients: price.clienteValorProdutos
                              ? price.clienteValorProdutos.map((cvp) => ({
                                    ...parseClient(cvp.cliente),
                                    clientName: cvp.cliente.nome,
                                    name: '',
                                }))
                              : [],
                      }
                  })
            : [],
        id: data.id_produto,
        isAtive: Boolean(data.flag_active),
        autoSum: Boolean(data.flag_autosoma),
        autoPrice: Boolean(data.flag_autopreco),
        weight: data.weight,
        branchStocks: data.estoqueFiliais?.map((estoqueFilial) => ({
            id: estoqueFilial.id_estoque_filial,
            branchId: estoqueFilial.id_filial,
            stock: Number(estoqueFilial.estoque),
            unitType: data.unit_measurement,
            idealStock: data.stock_ideal ? Number(data.stock_ideal) : null,
            minStock: data.stock_minimo ? Number(data.stock_minimo) : null,
        })),
        supplier: data.fornecedor ? parseSupplier(data.fornecedor) : null,
        localizations:
            data.localizacoesProduto?.map((lp) => ({
                value: lp.valor,
                branchId: lp.id_filial,
            })) ?? [],
        productsStockAfterOperation: data.produtosCargaEstoquePosOperacao?.map(
            (v) => ({
                product: ParseProduct(v.produtoCarga),
                qnt: Number(v.quantidade_produto),
            })
        ),
    }
}

export function parseProductHistoric(
    data: ProductHistoricApiData,
    coins: CoinInfo[]
): ProductHistoricInfo {
    return {
        id: 0,
        operationId: data.id_operacao,
        date: data.data.split('T')[0],
        hour: data.hora,
        value: Number(data.valor),
        qnt: Number(data.quantidade),
        type: data.tipo,
        isSummerTime: Boolean(data.flag_horario_verao),
        agentName: data.nome_agente,
        branchName: data.nome_filial,
        coin: coins.find((c) => c.id === data.id_moeda),
        exchange: data.cambio
            ? {
                  amount: Number(data.valor_moeda_estrangeira),
                  coin: coins.find(
                      (c) => c.id === data.id_moeda_estrangeira
                  ) as CoinInfo,
                  exchange: Number(data.cambio),
                  flagReverseExchange: data.flag_cambio_reverso as number,
              }
            : null,
    }
}

export function parseLoadStock(data: LoadStockApiData): LoadStockInfo {
    return {
        id: data.id_carga_estoque,
        account: {
            name: data.usuario?.nome,
        },
        cashier: {
            name: data.caixa?.nome,
        },
        date: data.data,
        hour: data.hora,
        branch: data.filial ? parseBranch(data.filial) : null,
        isSummerTime: !!data.flag_horario_verao,
        products: data.produtosCargaEstoque.map((pce) => ({
            id: pce.id_produto_carga_estoque,
            qnt: pce.quantidade_produto,
            negativeQnt: pce.quantidade_produto_negativo,
            product: ParseProduct(pce.produto),
        })),
    }
}

export function parseTransferStock(
    data: TransferStockApiData
): TransferStockInfo {
    return {
        id: data.id_transferencia_estoque,
        account: {
            name: data.usuario?.nome,
        },
        cashier: {
            name: data.caixa?.nome,
        },
        date: data.data,
        hour: data.hora,
        exitDate: new Date(`${data.data_saida} ${data.hora_saida}`),
        arrivalDate: new Date(`${data.data_chegada} ${data.hora_chegada}`),
        originBranch: parseBranch(data.filialOrigem),
        destinyBranch: parseBranch(data.filialDestino),
        isSummerTime: !!data.flag_horario_verao,
        products: data.produtosTransferenciaEstoque.map((pte) => ({
            id: pte.id_produto_transferencia_estoque,
            qnt: pte.quantidade_produto,
            product: ParseProduct(pte.produto),
        })),
        bill: data.numero_fatura,
        note: data.nota ? parseNote(data.nota) : null,
        employee: parseEmployee(data.funcionario),
        vehicle: parseVehicle(data.veiculo),
        estimatedDistance: Number(data.distancia_estimada),
        cdc: data.cdc_fatura,
        qrCode: data.qrcode,
        statusEletronicNote: data.status_fatura_eletronica,
        logEletronicNote: data.log_envio_fatura,
    }
}

export async function createProduct(
    infos: CreateProductInterface
): Promise<number> {
    try {
        const res = await post('product', infos, {
            useRecaptcha: true,
        })
        return res.data.data.product.id_produto
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.message.includes('Barcodes already in use')
        ) {
            const barcodes = error.data.data.message.split(': ')[1].split(',')
            throw new BarcodeAlreadyInUseError(barcodes)
        }
        throw error
    }
}

export async function updateProduct(
    infos: UpdateProductInterface
): Promise<void> {
    try {
        await put('product', infos, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.message.includes('Barcodes already in use')
        ) {
            const barcodes = error.data.data.message.split(': ')[1].split(',')
            throw new BarcodeAlreadyInUseError(barcodes)
        }
        throw error
    }
}

export async function listProducts(
    infos: ListProductInterface
): Promise<ListReturn<ProductInfo>> {
    const res = await post('product/list', infos)
    return {
        data: res.data.data.data.map((data: ProductApiData) =>
            ParseProduct(data)
        ),
        count: res.data.data.count,
    }
}

export async function getProductById(id: number): Promise<ProductInfo> {
    const res = await post('product/specific', {
        filter: [
            {
                name: 'id_produto',
                value: [id],
            },
        ],
    })

    return ParseProduct(res.data.data.product[0])
}

export async function generateInventory(
    data: GenerateInventoryInterface
): Promise<void> {
    const res = await post('product/inventory', data, {
        useRecaptcha: true,
    })
    return res.data.data
}

export async function loadStock(data: LoadStockInterface): Promise<void> {
    await post('product/load-stock', data, {
        useRecaptcha: true,
    })
}

export async function getTotalInventory(): Promise<Record<string, number>> {
    const res = await get('product/total-inventory')
    const rawData = res.data.data
    const data: any = {}

    for (const row of rawData) {
        if (row.id_filial) {
            if (!data[row.id_filial])
                data[row.id_filial] = {
                    isBranch: true,
                }
            if (!data[row.id_filial][row.unidade_medida])
                data[row.id_filial][row.unidade_medida] = {
                    stock: 0,
                    totalCost: 0,
                }
            data[row.id_filial][row.unidade_medida].stock += Number(row.stock)
            data[row.id_filial][row.unidade_medida].totalCost += Number(
                row.totalCost
            )
        } else {
            if (!data[row.unidade_medida])
                data[row.unidade_medida] = {
                    stock: 0,
                    totalCost: 0,
                }
            data[row.unidade_medida].stock += Number(row.stock)
            data[row.unidade_medida].totalCost += Number(row.totalCost)
        }
    }

    console.log(data)

    return data
}

export async function listProductHistoric(
    data: ListProductHistoricInterface,
    coins: CoinInfo[]
): Promise<ListReturn<ProductHistoricInfo>> {
    const res = await post('product/list-historic', data)
    return {
        data: res.data.data.data.map(
            (data: ProductHistoricApiData, i: number) => ({
                ...parseProductHistoric(data, coins),
                id: i + 1,
            })
        ),
        count: Number(res.data.data.count),
    }
}

export async function listLoadStock(
    data: ListLoadStockInterface
): Promise<ListReturn<LoadStockInfo>> {
    const res = await post('product/load-stock/list', data)
    return {
        data: res.data.data.data.map(parseLoadStock),
        count: Number(res.data.data.count),
    }
}

export async function createTransferStock(
    data: CraeteTransferStockInterface
): Promise<void> {
    await post('product/transfer-stock', data, {
        useRecaptcha: true,
    })
}

export async function updateTransferStock(
    data: UpdateTransferStockInterface
): Promise<void> {
    await put('product/transfer-stock', data, {
        useRecaptcha: true,
    })
}

export async function listTransferStock(
    data: ListTransferStock
): Promise<ListReturn<TransferStockInfo>> {
    const res = await post('product/transfer-stock/list', data)
    return {
        data: res.data.data.data.map(parseTransferStock),
        count: Number(res.data.data.count),
    }
}
