import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { AccountInfo, useAccount } from './account_context'
import { CoinInfo } from './coin_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import { createSpent, createSpentType, listSpentTypes } from '../services/spent'
import { useNavigate } from 'react-router-dom'
import { CashierInfo } from './cashier_context'
import { BranchInfo } from './branch_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { PaymentMethodInfo } from './payment_method_context'
import { CashierClosedBuyError } from '../services/buy'

export interface SpentTypeInfo {
    id: number
    name: string
}

export interface SpentInfo {
    id: number
    spentType: SpentTypeInfo | null
    coin: CoinInfo | null
    cashier: Partial<CashierInfo> | null
    account: Partial<AccountInfo> | null
    value: number
    paymentMethod: PaymentMethodInfo | null
    branch: BranchInfo | null
    description?: string
    date?: string
    hour?: string
    isSummerTime?: boolean
}

export interface CreateSpentInfo extends Omit<SpentInfo, 'id'> {
    newSpentTypeName: string
    isEditing: boolean
}

export interface SpentError {
    spentType: string
    coin: string
    description: string
    value: string
    newSpentTypeName: string
    paymentMethod: string
    branch: string
}

export const createSpentInfoDefault: CreateSpentInfo = {
    spentType: null,
    coin: null,
    cashier: null,
    account: null,
    description: '',
    value: 0,
    isEditing: false,
    newSpentTypeName: '',
    paymentMethod: null,
    branch: null,
}

export const spentErrorDefault: SpentError = {
    spentType: '',
    coin: '',
    description: '',
    value: '',
    newSpentTypeName: '',
    paymentMethod: '',
    branch: '',
}

export interface SpentContextInterface {
    createSpentInfo: CreateSpentInfo
    setCreateSpentInfo: Dispatch<SetStateAction<CreateSpentInfo>>
    spentError: SpentError
    setSpentError: Dispatch<SetStateAction<SpentError>>
    spentTypes: SpentTypeInfo[]
    handleLoadSpentTypes(): Promise<void>
    handleCreateSpentType(): Promise<void>
    validate(): boolean
    handleCreateNewSpent(): Promise<void>
}

const SpentContext = createContext<SpentContextInterface>({
    createSpentInfo: createSpentInfoDefault,
    setCreateSpentInfo: () => {},
    spentError: spentErrorDefault,
    setSpentError: () => {},
    spentTypes: [],
    handleLoadSpentTypes: () => Promise.resolve(),
    handleCreateSpentType: () => Promise.resolve(),
    validate: () => false,
    handleCreateNewSpent: () => Promise.resolve(),
})

export function useSpent() {
    return useContext(SpentContext)
}

interface Props {
    children: ReactNode
}

export const SpentContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [createSpentInfo, setCreateSpentInfo] = useState<CreateSpentInfo>(
        createSpentInfoDefault
    )
    const [spentError, setSpentError] = useState<SpentError>(spentErrorDefault)
    const [spentTypes, setSpentTypes] = useState<SpentTypeInfo[]>([])
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    const handleLoadSpentTypes = async () => {
        if (spentTypes.length) return

        loading.logicShow()
        try {
            const res = await listSpentTypes()
            setSpentTypes(res)
        } catch (error) {
            snack.connectionFail(error)
        }
        loading.logicHide()
    }

    const handleCreateSpentType = async () => {
        if (!createSpentInfo.newSpentTypeName) {
            setSpentError((prev) => ({
                ...prev,
                newSpentTypeName: 'Campo obrigatório',
            }))
            return
        }

        loading.show()
        try {
            const id = await createSpentType(createSpentInfo.newSpentTypeName)
            const res = await listSpentTypes()
            setSpentTypes(res)
            setCreateSpentInfo((prev) => ({
                ...prev,
                spentType: res.find((r) => r.id === id) ?? null,
                newSpentTypeName: '',
            }))
            setSpentError((prev) => ({
                ...prev,
                spentType: '',
                newSpentTypeName: '',
            }))
        } catch (error) {
            snack.connectionFail(error)
        }
        loading.hide()
    }

    const validate = (): boolean => {
        let isOk = true
        const newSpentError = { ...spentErrorDefault }

        if (!createSpentInfo.spentType) {
            newSpentError.spentType = 'Campo obrigatório'
            isOk = false
        }
        if (!createSpentInfo.coin) {
            newSpentError.coin = 'Campo obrigatório'
            isOk = false
        }
        if (!createSpentInfo.value) {
            newSpentError.value = 'Campo obrigatório'
            isOk = false
        }
        if (!createSpentInfo.paymentMethod?.id) {
            newSpentError.paymentMethod = 'Campo obrigatório'
            isOk = false
        }
        if (
            accountInfo?.isMaster &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !createSpentInfo.branch
        ) {
            newSpentError.branch = 'Campo obrigatório'
            isOk = false
        }

        setSpentError({ ...newSpentError })
        return isOk
    }

    const handleCreateNewSpent = async () => {
        loading.show()
        try {
            await createSpent({
                id_tipo_gasto: createSpentInfo.spentType?.id ?? 0,
                id_moeda: createSpentInfo.coin?.id ?? 0,
                valor: createSpentInfo.value,
                id_metodo_pagamento: createSpentInfo.paymentMethod?.id ?? 0,
                descricao: createSpentInfo.description
                    ? createSpentInfo.description
                    : undefined,
                id_filial_master: createSpentInfo.branch?.id,
            })
            snack.success('Gasto criado com sucesso!')
            navigate('/system/spent')
            setCreateSpentInfo({ ...createSpentInfoDefault })
        } catch (error) {
            if (error instanceof CashierClosedBuyError)
                snack.error('O caixa está fechado!')
            else snack.connectionFail(error)
        }
        loading.hide()
    }

    return (
        <SpentContext.Provider
            value={{
                createSpentInfo,
                setCreateSpentInfo,
                spentError,
                setSpentError,
                spentTypes,
                handleLoadSpentTypes,
                handleCreateSpentType,
                validate,
                handleCreateNewSpent,
            }}
        >
            {children}
        </SpentContext.Provider>
    )
}
